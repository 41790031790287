/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andrisam
==============================================================================================*/
@import "sass-lib"; 

@media all and (max-width: 1800px) {
	.home-about {
		.bg_shd {left: 35%;}
	}
	.home-cta {
		.bg_shd {right: 30%;}
	}

}

@media all and (max-width: 1520px) {
	.home-about {
		.bg_shd {left: 23%;}
	}
	.home-cta {
		.bg_shd {right: 20%;}
	}
}

@media all and (max-width: 1350px) {

	.fxo-widget-iframe {right: 50px !important;} 

	.wrapper, .container {width: 905px;} 

	header {
		.menu-top {
			a {				 
				@include boxSizing(border-box);
			} 
		}
		nav {
			ul {
				li {
					a {
						font-size: 14px; padding: 0 15px;     
						&:after {}    
					}   
					&:last-child { 
						a {}    
					}
				}
			}			
		}
		&.float {	
			.menu-top {
				margin-bottom: 5px;
				.lang {
					.act {padding-top: 5px;}
				}
			} 			
			nav {
				ul {
					li {
						a {
							font-size: 14px; padding: 0 15px; 								
						}
						ul {
							li {
								a {
								
								}
							}
						}
					}
				}
			}
		}
	}
	.col-logo {
		padding: 25px 0;
		.row {
			text-align: center; 
			.col {
				margin: 0 20px; float: none; display: inline-block;   width: 15%; vertical-align: middle; 
				a {
					height: 50px; 
					img {height: 100%;} 
				}
			}  
		}
	}

	.homeslider { 
		.slick-arrow {} 
		.img {
			height: 450px;   			
		} 
	}
	.home-about { 
		.bgimg {
			/*background-position: 350px 0 !important; */        
		}
		.bg_shd {left: 12%;}
	}
	.listing-news {   
		.container {padding: 85px 0 60px 0;}           
		.row {
			a {
				min-height: 312px;  
			}
		}
	}
	.box-news {
		.row {
			.col {
				position: relative; width: 50%; 
				&:nth-child(2) {left: 0;}
				&:nth-child(4) {left: 0;}
				&.large {
					width: 100%;
					a {
						&:before {top:45%;}    
					}
				} 
				.text {padding: 60px 60px 60px 60px;}     
			}
		}
	}

	.home-testi {
		.container {
			min-height: 450px;   
			&:after {
				width:100%; height: 345px; background-size: 100%;  
			} 
		}
		.slick-slider { 
			margin: 0 65px 0 85px; overflow: visible; height: 250px;
			.slick-dots {bottom: 15px; padding: 0 145px 0 145px !important;}   
		} 
		.txt-testi {
			padding: 15px 15px 0 50px; 	
			a {
				&:after {
					width: 37px; height: 38px; background-size: 100% 100%;    
					left: -43px; 
				} 
			}		
			.ts-title {
				font-size: 20px; height: 50px; line-height: 24px;     
			}
			.ts-des {font-size: 14px; line-height: 20px; height: 63px; margin-bottom: 15px;}        
			.ts-by {font-size: 13px;}
		}
	}
	.home-cta {
		.text {width: 420px;} 
		.container {
			padding: 75px 0; 
		}
		.bg_shd {right: 30%;}
		.bgimg.v2 {background-position: -170px 0 !important;}       
	}
	.home-address {
		.col {
			h2 {line-height: 30px;}  
			&.des {padding: 0 70px;}      
			&.address {padding: 0 30px 0 30px;}              
		}
	}

	.mid-content {
		.banner-slider {
			&.testi {
				.slick-dots {
				}
			}
			&.v2 {
				margin-bottom: 75px;   
			}
		}
	}
	.inner-contact {
		padding: 0 55px;   
		.list-address {
			.row {
				.col {
					.row {
						.cl {padding: 0 0 0 0;}     
					}
				}
			}
		}
		.addmission {
			.row {
				.col {
					&:first-child {padding-right:0;}       
					span {font-weight: normal;}    
				}
			}
		}
		.list-map {
			.row {
				.col {
					.map {
						height: 340px;
						iframe{ height: 340px; }
					}
				}
			}
		}
	}

	.careermap { 
		.map-location {} 
		.point-map {	
			padding: 0;		
			a {
				&.jkt {left: -10px;}
				&.bali {right: 255px; top: 100px;}       
			}
 		} 
		.des-map {} 
		.middle {} 
	}

	.wrap-tankyou {
		.container {padding: 80px 0 80px 0;}    
	}


	footer {
		padding: 30px 0 25px 0;    
		.ftop {
			font-size: 12px;
			.cl { 
				&.mail {
					padding: 5px 15px 5px 35px; 
					&:before {width:25px; height: 25px; background-size: 100%;}       
				}
				&.telp {
					padding: 5px 0 5px 35px;  
					a {
						padding: 0; 
						&:before {width:25px; height: 25px; background-size: 100%;}    
					} 
				}
			}
		} 
		.copyright {font-size: 11px;}    
	}
	.scrollup {right: 10px;} 

	
}

@media all and (max-width: 1280px) {
	.home-about {
		.bg_shd {left: 0%;}
		&:before { position: absolute;width: 100%; height: 100%; background: rgba(127, 200, 173, 0.7); content: "";left: 0;top: 0;z-index: 2;}
	}
	.home-cta {
		.bg_shd {right: 20%;}
	}
  .mid{
    .side-left{
      width: 260px;
    }
    .side-right{
      padding-left: 260px;
      .filter-row{
        justify-content: space-between;
      }
      .cart{
        padding: 0;
        table{
          td, th{
            &:nth-child(2){
              padding-right: 0;
            }
          }
        }
      }
      .payment{
        .payment-detail{
          .box-payment{
            border-bottom: 0;
          }
        }
        .accordion-payment{
          .acclist{
            .acc-title{
              .logo-payment{
                img{
                  height: 20px;
                  margin-left: 7px;
                }
              }
            }
            .acc-descrip{
              .box-select{
                .selected{
                  padding-right: 34px;
                  &::after{
                    right: 9px;
                  }
                }
                figure{
                  width: 90px;
                  img{
                    height: 20px;
                  }
                }
                label{
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .summary{
        top: unset;
        bottom: 27px;
        right: 27px;
        width: calc( 100% - 260px - 27px );
        display: flex;
        justify-content: space-between;
        padding: 12px;
        align-items: center;
        background: #ECF4F4;
        box-shadow: 0px 15px 15px rgba(17, 17, 17, 0.1);
        border-radius: 4px;
        h3{
          margin: 0;
          &:first-child{
            width: 95px;
          }
        }
        .btn-yellow{
          width: 245px;
        }
      }
      .wrap-day{
        .day{
          h3{
            font-size: 20px;
          }
        }
      }
      .school-item{
        .wrap-items{
          figcaption{
            min-height: 130px;
          }
        }
      }
      .invoice{
        .flex-grid{
          .grid-l, .grid-r{
            flex: 0 0 50%;
            max-width: 50%;
            line-height: 1.4;
          }
          .grid-r{
            text-align: right;
          }
        }
        .recipient{
          .flex-grid{
            display: block;
            .grid-l, .grid-in, .grid-r{
              display: block;
              padding-left: 0;
              margin-bottom: 10px;
              text-align: left;
            }
          }
        }
      }
    }
    .row{
      .column{
        &.column-33, &.column-65, &.column-35{
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
  .eca-top{
    .wrapper{
      width: auto;
      .container{
        .content-centered{
          margin: 0;
          padding: 0;
        }
      }
    }
    .container{
      width: auto;
      margin: 0 60px;
    }
  }
  .eca-content{
    .wrapper{
      width: auto;
      margin: 0 40px;
      .wrap-rounded{
        .content{
          padding: 50px 30px;
          .content-centered{
            margin: 0;
          }
        }
      }
    }
  }
}